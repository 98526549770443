import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    show: Boolean
  }

  initialize() {
    if (this.showValue) {
      this.element.showModal()
    }
  }

  open() {
    this.element.showModal()
  }

  close() {
    this.element.close()
  }
}
