import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dialog', 'header', 'description']

  connect() {
    Turbo.setConfirmMethod((message, element) => {
      let dialog = this.dialogTarget
      this.headerTarget.innerText = element.dataset.header
      this.descriptionTarget.innerText = message
      dialog.showModal()

      return new Promise((resolve, reject) => {
        dialog.addEventListener('keydown', (e) => {
          if (e.keyCode === 27) {
            reject(false)
          }
        }, { once: true })
        dialog.addEventListener('close', (e) => {
          resolve(dialog.returnValue === 'confirm')
        }, { once: true })
      })
    })
  }
}
