import { Controller } from "@hotwired/stimulus";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from '@tiptap/extension-text-style'
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import TipTapImage from "@tiptap/extension-image";
import Bold from "@tiptap/extension-bold";
import Document from '@tiptap/extension-document'
import UploadImage from 'tiptap-extension-upload-image';
import Dropcursor from '@tiptap/extension-dropcursor';
import CharacterCount from '@tiptap/extension-character-count'

import { post } from '@rails/request.js'


export default class extends Controller {
  static targets = [
    "editor",
    "textarea",
    "boldButton",
    "italicButton",
    "linkButton",
    "charCounter",
    "wordsCounter",
    "pagesCounter",
    "circleCounter"
  ];

  connect() {
    const controller = this;
    this.limit = parseInt(this.element.dataset.characterLimit);

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit.configure({ marks: { bold: false } }),
        Bold.extend({
          renderHTML({ mark, HTMLAttributes }) {
            const { style, ...rest } = HTMLAttributes;
            const newStyle = `font-weight: bold; ${style || ""}`.trim();
            return ["span", { ...rest, style: newStyle }, 0];
          },
        }),
        Link.configure({ openOnClick: false, autolink: true }),
        TextAlign.configure({ types: ["heading", "paragraph"] }),
        TextStyle,
        HorizontalRule,
        Document,
        Dropcursor,
        TipTapImage,
        UploadImage.configure({
          uploadFn: this.uploadFn
        }),
        CharacterCount.configure({
          limit: this.limit,
        }),
      ],
      content: this.textareaTarget.value || "<p>Your content here...</p>",
      onUpdate: ({ editor }) => {
        // Synchronize editor content with textarea
        this.textareaTarget.value = editor.getHTML();
        this.charCounterTarget.innerText = editor.storage.characterCount.characters();
        this.wordsCounterTarget.innerText = editor.storage.characterCount.words();
        this.pagesCounterTarget.innerText = this.pages();
        this.circleCounterTarget.setAttribute('stroke-dasharray', this.percentage());
      },
      editorProps: {
        attributes: {
          class: "prose-lg focus:outline-none max-w-none h-full border-none"
        },
      },
      onSelectionUpdate({ editor }) {
        controller.boldButtonTarget.classList.toggle("bg-neutral", editor.isActive('bold'))
        controller.italicButtonTarget.classList.toggle("bg-neutral", editor.isActive('italic'))
        controller.linkButtonTarget.classList.toggle("bg-neutral", editor.isActive('link'))

      },
    });
    this.charCounterTarget.innerText = this.editor.storage.characterCount.characters();
    this.wordsCounterTarget.innerText = this.editor.storage.characterCount.words();
    this.pagesCounterTarget.innerText = this.pages();
    this.circleCounterTarget.setAttribute('stroke-dasharray', this.percentage());
  }

  toggleBold(event) {
    this.editor.chain().focus().toggleBold().run();
    this.boldButtonTarget.classList.toggle("bg-neutral", this.editor.isActive('bold'))
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
    this.italicButtonTarget.classList.toggle("bg-neutral", this.editor.isActive('italic'))
  }

  addLink() {
    const url = window.prompt("Enter link URL:", "https://");
    if (url) {
      this.editor.chain().focus().toggleLink({ href: url }).run();
    }
  }

  removeLink() {
    this.editor.chain().focus().unsetLink().run();
  }

  addImage() {
    this.editor.chain().focus().addImage().run()
  }

  setAlignment(event) {
    const alignment = event.currentTarget.dataset.alignment;
    this.editor.chain().focus().setTextAlign(alignment).run();
  }

  toggleList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run();
  }

  addHorizontalRule() {
    this.editor.chain().focus().setHorizontalRule().run();
  }

  setHeading(event) {
    const level = parseInt(event.currentTarget.dataset.level, 10);
    this.editor.chain().focus().toggleHeading({ level }).run();
  }

  percentage() {
    const percentage = Math.round((100 / this.limit) * this.editor.storage.characterCount.characters());
    return `calc(${percentage} * 31.4 / 100) 31.4`
  }

  pages() {
    return Math.round(this.editor.storage.characterCount.words() / parseInt(this.element.dataset.wordLimit))
  }

  async uploadFn(file) {

    var formData = new FormData();
    formData.append("file", file);
    const response = await post('/uploader/image', {
      body: formData
    })
    if (response.ok) {
      const body = await response.json
      return body.url
    }
  }
}
