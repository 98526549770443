import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = [ "select" ];
  static values = {
    options: Object
  };

  connect() {
    this.choices = new Choices(this.selectTarget);
  }

  disconnect() {
    this.choices.destroy();
  }
}
