import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "fileField", "outputContainer", "template"]

  connect() {
    this.multiple = this.fileFieldTarget.multiple === true
  }

  readURL() {
    const input = this.fileFieldTarget
    const outputContainer = this.outputContainerTarget

    if (input.files) {
      const newFiles = Array.from(input.files)

      // If the input is not multiple, clear existing images
      if (!this.multiple) {
        outputContainer.innerHTML = ''
        newFiles.splice(1)
      }

      // Clear existing non-persisted images
      const nonPersistedImages = outputContainer.querySelectorAll('.image-thumb[data-persisted="false"]')
      nonPersistedImages.forEach(image => image.remove())

      // Add new files that are not already displayed
      newFiles.forEach((file, index) => {
        const reader = new FileReader()
        reader.onload = () => {
          const fileSrc = reader.result
          const newDiv = this.createOutputDiv(fileSrc, index)
          outputContainer.insertAdjacentHTML("afterbegin", newDiv)
        }
        reader.readAsDataURL(file)
      })
    }
  }

  createOutputDiv(imageSrc, index) {
    const template = this.templateTarget.innerHTML;
    let content = template.replace(/DATA_INDEX/g, index);
    content = content.replace(/IMG_PATH/g, imageSrc);
    return content;
  }

  removeUrl(event) {
    const index = event.target.closest('button').dataset.index
    const input = this.fileFieldTarget
    const outputContainer = this.outputContainerTarget

    // Remove the file from the input
    const dataTransfer = new DataTransfer()
    Array.from(input.files).forEach((file, i) => {
      if (i != index) {
        dataTransfer.items.add(file)
      }
    })
    input.files = dataTransfer.files

    // Remove the corresponding div
    outputContainer.removeChild(event.target.closest('.image-thumb'))
  }

  removeThumbnail(event) {
    this.outputContainerTarget.removeChild(event.target.closest('.image-thumb'))
  }
}