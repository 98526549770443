import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabButton", "tabPanel"];

  connect() {
    this.activateTab(this.tabButtonTargets[0]); // Aktywuj pierwszy tab przy starcie
  }

  changeTab(event) {
    const selectedButton = event.currentTarget;
    this.activateTab(selectedButton);
  }

  activateTab(selectedButton) {
    const targetTab = selectedButton.dataset.socialMediaTab;

    // Deaktywuj wszystkie przyciski i ukryj wszystkie panele
    this.tabButtonTargets.forEach((button) => {
      button.classList.remove("btn-neutral");
      button.classList.add("btn-ghost");
    });

    this.tabPanelTargets.forEach((panel) => {
      panel.classList.add("hidden");
    });

    // Aktywuj wybrany przycisk
    selectedButton.classList.remove("btn-ghost");
    selectedButton.classList.add("btn-neutral");

    // Pokaż odpowiedni panel
    const activePanel = this.tabPanelTargets.find(
      (panel) => panel.dataset.socialMediaTabContent === targetTab
    );

    if (activePanel) {
      activePanel.classList.remove("hidden");
    }
  }
}
