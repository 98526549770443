// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import "trix"
import "@rails/actiontext"

Trix.config.blockAttributes.heading1.tagName = "h2"
Trix.config.blockAttributes.default.tagName = "p"
Trix.config.blockAttributes.default.breakOnReturn = true;

Trix.Block.prototype.breaksOnReturn = function(){
  const attr = this.getLastAttribute();
  const config = Trix.getBlockConfig(attr ? attr : "default");
  return config ? config.breakOnReturn : false;
};
Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function(){
  if(this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
    return this.startLocation.offset > 0
  } else {
    return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false;
  }
};
